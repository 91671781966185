import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import { Helmet } from "react-helmet";
import Nav from "../components/Nav/Nav";
import * as ACFModules from "../modules/Modules";
import Fallback from "../modules/Fallback";

const ModulePages = ({ data: { wpPage } }) => {
  const { title, template, slug, seo, isFrontPage } = wpPage;
  const {
    pageModules: { modules },
  } = template;
  const pageTitle = title === "Home" ? "" : title + " -";

  const hasHero = modules?.find(
    (module) =>
      module.fieldGroupName === "Template_Modules_Pagemodules_Modules_Hero"
  );
  return (
    <>
      <Seo seo={seo} />
      <main className={`modules${isFrontPage ? " is-front-page" : ""}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle} Seesaw Studio</title>
        </Helmet>
        {hasHero ? "" : <Nav position="top" slug={slug} />}
        {modules &&
          modules.map((module, id) => {
            if (Object.keys(module).length > 0) {
              const moduleName = module.fieldGroupName.replace(
                "Template_Modules_Pagemodules_Modules_",
                ""
              );
              const Component = ACFModules[moduleName] || Fallback;
              return <Component key={id} {...module} pageLocation={slug} />;
            } else {
              return <></>;
            }
          })}
      </main>
    </>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      isFrontPage
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }

      template {
        ... on WpTemplate_Modules {
          pageModules {
            modules {
              ... on WpTemplate_Modules_Pagemodules_Modules_Hero {
                fieldGroupName
                textLeft
                textRight
                images {
                  altText
                  publicUrl
                  caption
                  sourceUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_Content {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                backgroundColour
                heading
                text1
                text2
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TextAndImage {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                backgroundColour
                layout
                heading
                text1
                text2
                image {
                  publicUrl
                  mediaItemUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TextWithImageLayers {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                layout
                heading
                text1
                text2
                backgroundColour
                media {
                  type
                  video {
                    mediaItemUrl
                  }
                  image {
                    altText
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_ProjectHero {
                fieldGroupName
                backgroundColour
                heading
                content
                content2
                image {
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
                accordionList {
                  info
                  label
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TwoColumnImages {
                fieldGroupName
                padding {
                  paddingBottomMobile
                  paddingTopMobile
                }
                layout
                mobileLayout
                backgroundColour
                leftImage {
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
                rightImage {
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                  altText
                }
                videoLeft
                videoRight
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TwoPortraitImages {
                fieldGroupName
                mobileType
                backgroundColour
                slides {
                  leftVideo
                  rightVideo
                  leftImage {
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                    altText
                  }
                  rightImage {
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                    altText
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_SingleMedia {
                fieldGroupName
                enablePadding
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                mediaType
                disableParallaxEffect
                insetAnimation
                backgroundColour
                images {
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: FULL_WIDTH
                      )
                    }
                  }
                  altText
                }
                video {
                  mediaItemUrl
                }
                videoUrl
                link
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_ScrollingScreens {
                fieldGroupName
                backgroundColour
                screens {
                  image {
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: DOMINANT_COLOR
                          layout: FULL_WIDTH
                        )
                      }
                    }
                    altText
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TwoImagesWithText {
                fieldGroupName
                padding {
                  paddingBottomMobile
                  paddingTopMobile
                }
                disableParallaxScroll
                backgroundColour
                content
                layout
                largeVideo
                largeImage {
                  altText
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                }
                smallVideo
                smallImage {
                  altText
                  publicUrl
                  localFile {
                    extension
                    childImageSharp {
                      gatsbyImageData(placeholder: DOMINANT_COLOR)
                    }
                  }
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_List {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                backgroundColour
                heading
                list {
                  hoverImage {
                    publicUrl
                    localFile {
                      extension
                      childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR)
                      }
                    }
                    altText
                  }
                  name
                  role
                  link
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_AccordionList {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTop
                  paddingTopMobile
                }
                backgroundColour
                accordionList {
                  content
                  heading
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_TwoColumnText {
                fieldGroupName
                padding {
                  paddingBottom
                  paddingBottomMobile
                  paddingTopMobile
                  paddingTop
                }
                parallaxEffect
                textLeft
                textRight
                backgroundColour
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_LinkBlock {
                fieldGroupName
                backgroundColour
                links {
                  link {
                    title
                    url
                  }
                  title
                  text
                  text2
                }
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_WorkFeed {
                fieldGroupName
                backgroundColour
                heading
                text1
                text2
              }
              ... on WpTemplate_Modules_Pagemodules_Modules_NextPage {
                fieldGroupName
                backgroundColour
                nextPage {
                  ... on WpPage {
                    id
                    title
                    uri
                    featuredImage {
                      node {
                        altText
                        publicUrl
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                  ... on WpProject {
                    id
                    title
                    uri
                    featuredImage {
                      node {
                        altText
                        publicUrl
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ModulePages;

